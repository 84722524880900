<template>
  <section>
    <h4 class="pb-2 font-bold dark:text-white" v-text="$t('home.featured')" />
    <div class="flex justify-center">
      <div class="grid grid-cols-1 gap-y-6 gap-x-6 lg:grid-cols-3 featured">
        <div class="relative">
          <img
            class="object-contain w-full rounded-lg shadow hover:shadow-md"
            src="/img/PC_Blend_HT_LCF.jpg"
            alt="PC_Blend_HT_LCF"
          />
          <div class="absolute bottom-1 inset-x-0 flex justify-center">
            <router-link
              class="main-button"
              type="button"
              v-text="$t('shop.view')"
              tag="button"
              to="/product/PC%20Blend%20HT%20LCF/PC%20Blend%20HT%20LCF%20-%201.75mm%20-%20500g%20-%20Black"
            />
          </div>
        </div>
        <div class="relative">
          <img
            class="object-contain w-full rounded-lg shadow hover:shadow-md"
            src="/img/Bambu_printers.jpg"
            alt="Bambu Lab Sale 21p"
          />
          <div class="absolute bottom-1 inset-x-0 flex justify-center">
            <router-link
              class="main-button"
              type="button"
              v-text="$t('shop.view')"
              tag="button"
              to="/shop?query=bambu%20lab"
            />
          </div>
        </div>
        <!-- <div class="relative">
          <img
            class="object-contain w-full rounded-lg shadow hover:shadow-md"
            src="/img/HT_PLA_Pro_Matte.jpg"
            alt="HT_PLA_Pro_Matte"
          />
          <div class="absolute bottom-1 inset-x-0 flex justify-center">
            <router-link
              class="main-button"
              type="button"
              v-text="$t('shop.view')"
              tag="button"
              to="/product/HT-PLA%20Pro%20Matte/HT-PLA%20PRO%20Matte%20-%201.75mm%20-%20750g%20-%20White"
            />
          </div>
        </div> -->
        <div class="relative">
          <img
            class="object-contain w-full rounded-lg shadow hover:shadow-md"
            src="/img/TPU_Pro_Matte.jpg"
            alt="TPU_Pro_Matte"
          />
          <div class="absolute bottom-1 inset-x-0 flex justify-center">
            <router-link
              class="main-button"
              type="button"
              tag="button"
              v-text="$t('shop.view')"
              to="/product/TPU%20Pro%20Matte%2095A/TPU%20Pro%20Matte%2095A%20-%201.75mm%20-%201000g%20-%20Black"
            />
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: "Featured",
};
</script>
